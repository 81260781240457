<template lang="pug">
  el-button-group.locks__row
    el-popover(placement="bottom" :title="`Просматривают страницу: ${lockUsers.length}`" width="300" trigger="click")
      el-table(:data="lockUsers")
        el-table-column(label="ID" width="50")
          template(slot-scope="{ row }") {{ row.user_id }}
        el-table-column(label="Имя")
          template(slot-scope="{ row }") {{ row.user_name }}
        el-table-column(label="Вкладок" width="80" align="center")
          template(slot-scope="{ row }") {{ row.tabs }}
      el-button(slot="reference" icon="el-icon-view" :type="viewType") {{ lockUsers.length }}
    el-popover(placement="bottom" width="500" trigger="click")
      h3
        | Версия страницы: V{{version.version }}
      p
        template(v-if="!lastVersion") Вы используете последнюю версию
        template(v-else) Последняя версия - V{{ lastVersion.version }}. Ваша версия не является последней, обновите страницу чтобы не перезаписать изменения!
      el-table(:data="[version, ...currentVersions]")
        el-table-column(label="Версия" width="160" align="center")
          template(slot-scope="{ row, $index }")
            | V{{ row.version }}
            | {{ $index === 0 && currentVersions.length !== 0  ? ' (Текущая)' : '' }}
            | {{ $index === currentVersions.length && currentVersions.length !== 0 ? ' (Последняя)' : '' }}
        el-table-column(label="Пользователь" min-width="50" align="center")
          template(slot-scope="{ row }") {{ row.user_name }} (ID{{ row.user_id }})
        el-table-column(label="Дата" min-width="50" align="center")
          template(slot-scope="{ row }") {{ row.created_at }}
      el-button(slot="reference" :type="versionsType") V{{ version.version }}{{ currentVersions.length !== 0 ? ' / V' + lastVersion.version : '' }}
</template>

<script>
  import { notifyErrorRequest } from '@/utils/api'

  export default {
    name: 'Locks',
    props: {
      checkUrl: { type: String, default: null },
      lockId: { type: Number, default: null },
      locks: { type: Array, default: null },
      version: { type: Object, default: null }
    },
    data() {
      const locks = this.locks || []
      const versions = []
      return {
        currentLockId: this.lockId,
        currentLocks: this.copy(locks),
        currentVersions: this.copy(versions),
        oldLockUsers: [],
        oldVersions: this.copy(versions),
        interval: null
      }
    },
    beforeUnmount() {
      clearInterval(this.interval)
      notifyErrorRequest({
        url: 'locks/delete',
        method: 'post',
        data: {
          lockId: this.currentLockId
        }
      })
    },
    components: {
    },
    mounted() {
      if (!this.lockId || !this.locks || !this.version) {
        this.check()
      }
      this.interval = setInterval(this.check, 10000)
    },
    computed: {
      versionsAfterCurrent() {
        return this.currentVersions.filter(el => el.version > this.version.version)
      },
      lockUsers() {
        const locksMap = {}
        this.currentLocks.forEach(el => {
          if (!locksMap[el.user_id]) {
            locksMap[el.user_id] = {
              user_id: el.user_id,
              user_name: el.user_name,
              tabs: 0
            }
          }
          locksMap[el.user_id].tabs++
        })
        return Object.values(locksMap)
      },
      type() {
        if (this.lastVersion) {
          return 'danger'
        } else if (this.currentLocks.length > 0) {
          return 'warning'
        }
        return 'success'
      },
      viewType() {
        if (this.currentLocks.length > 0) {
          return 'warning'
        }
        return 'success'
      },
      versionsType() {
        if (this.lastVersion) {
          return 'danger'
        }
        return 'success'
      },
      listUrl() {
        return this.$route.fullPath.replace('/create', '/list')
      },
      lastVersion() {
        if (this.currentVersions.length === 0) {
          return null
        }
        return this.currentVersions[this.currentVersions.length - 1]
      }
    },
    async created() {
    },
    watch: {
      lockUsers: {
        deep: true,
        handler() {
          const removed = []
          const added = []
          this.lockUsers.forEach(el => {
            if (!this.oldLockUsers.some(oldEl => oldEl.user_id === el.user_id)) {
              added.push(el)
            }
          })
          this.oldLockUsers.forEach(el => {
            if (!this.lockUsers.some(oldEl => oldEl.user_id === el.user_id)) {
              removed.push(el)
            }
          })
          if (removed.length > 0 || added.length > 0) {
            this.oldLockUsers = JSON.parse(JSON.stringify(this.lockUsers))
          }
          removed.forEach(el => {
            this.$message({
              showClose: true,
              type: 'success',
              message: `Пользователь ${el.user_name} (ID${el.user_id}) закончил просмотр`
            })
          })
          added.forEach(el => {
            this.$message({
              showClose: true,
              type: 'warning',
              message: `Пользователь ${el.user_name} (ID${el.user_id}) начал просмотр`
            })
          })
        }
      },
      currentVersions: {
        deep: true,
        handler() {
          const added = []
          this.versionsAfterCurrent.forEach(el => {
            if (!this.oldVersions.some(oldEl => oldEl.version === el.version)) {
              added.push(el)
            }
          })
          if (added.length > 0) {
            this.oldVersions = JSON.parse(JSON.stringify(this.currentVersions))
          }
          added.forEach(el => {
            this.$message({
              showClose: true,
              type: 'error',
              message: `Пользователь ${el.user_name} (ID${el.user_id}) сохранил документ! Ваша версия не является последней, обновите страницу чтобы не перезаписать изменения.`
            })
          })
        }
      }
    },
    methods: {
      copy(object) {
        return JSON.parse(JSON.stringify(object))
      },
      async check() {
        const response = await notifyErrorRequest({
          method: 'post',
          url: this.checkUrl,
          data: {
            lockId: this.currentLockId,
            version: this.version.version
          }
        })
        this.currentLockId = response.data.lockId
        this.currentLocks = response.data.locks
        this.currentVersions = response.data.versions
      },
      emitCreate() {
        this.$emit('create')
      }
    },
  }
</script>
<style lang="sass">
  @import "@/assets/sass/mixin.scss"
  .locks
    &__row
      display: flex
      flex-flow: row nowrap
      & > span
        &:not(:last-child)
          margin-right: 5px
      .el-button
        padding-left: 15px
        padding-right: 15px
        @media(max-width: 1199px)
          padding-left: 8px
          padding-right: 8px
</style>
